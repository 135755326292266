import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  useMediaQuery,
  useTheme,
  Toolbar,
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import axios from "axios";

import { UserContext } from "../UserContext"; // Import UserContext

const ServiceProviderBills = () => {
  const theme = useTheme();
  const { user, logout } = useContext(UserContext);
  
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [bills, setBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [selectedBill, setSelectedBill] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = async () => {
    try {
      const response = await axios.get(`https://api.cope.ke/billing/${user.provider_id}`);
      setBills(response.data);
      setFilteredBills(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch service provider bills");
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredBills(
      bills.filter(
        (bill) =>
          bill.clientName.toLowerCase().includes(value) ||
          bill.email.toLowerCase().includes(value) ||
          bill.phoneNumber.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenEditModal = (bill) => {
    setSelectedBill(bill);
    setFormData(bill);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (bill) => {
    setSelectedBill(bill);
    setOpenDeleteModal(true);
  };

  const handleOpenAddModal = () => {
    setFormData({});
    setOpenAddModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setFormData({});
    setSelectedBill(null);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedBill(null);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setFormData({});
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.put(`https://api.cope.ke/service_providers_bills/${selectedBill.id}`, formData);
      setSuccess("Service provider bill updated successfully!");
      fetchBills();
      handleCloseEditModal();
    } catch (error) {
      setError("Failed to update service provider bill");
      setLoading(false);
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post(`https://api.cope.com/service_providers_bills`, formData);
      setSuccess("Service provider bill added successfully!");
      fetchBills();
      handleCloseAddModal();
    } catch (error) {
      setError("Failed to add service provider bill");
      setLoading(false);
    }
  };

  const handleDeleteSubmit = async () => {
    setLoading(true);
    try {
      await axios.delete(`https://api.cope.ke/service_providers_bills/${selectedBill.id}`);
      setSuccess("Service provider bill deleted successfully!");
      fetchBills();
      handleCloseDeleteModal();
    } catch (error) {
      setError("Failed to delete service provider bill");
      setLoading(false);
    }
  };

  const handleCloseError = () => setError("");
  const handleCloseSuccess = () => setSuccess("");

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Client Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBills.map((bill) => (
            <TableRow key={bill.id} onClick={() => handleOpenEditModal(bill)}>
              <TableCell>{new Date(bill.date).toLocaleString()}</TableCell>
              <TableCell>{bill.clientName}</TableCell>
              <TableCell>{bill.email}</TableCell>
              <TableCell>{bill.phoneNumber}</TableCell>
              <TableCell>{`$${bill.amount.toFixed(2)}`}</TableCell>
              <TableCell>{bill.status}</TableCell>
              <TableCell align="right">
                <IconButton onClick={(e) => { e.stopPropagation(); handleOpenEditModal(bill); }}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={(e) => { e.stopPropagation(); handleOpenDeleteModal(bill); }}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography color="#00695C" variant="h4" gutterBottom>Your Bills</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search bills"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
          sx={{ ml: 2 }}
        >
          Add Bill
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredBills.length > 0 ? (
        renderTable()
      ) : (
        <Typography color="red" variant="body1" sx={{ mt: 4 }}>
          No bills found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={handleCloseEditModal} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>
            Edit Bill
          </Typography>
          <Box component="form" onSubmit={handleEditSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="clientName"
              label="Client Name"
              name="clientName"
              value={formData.clientName || ""}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email || ""}
              onChange={handleChange}
              type="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber || ""}
              onChange={handleChange}
              type="tel"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="amount"
              label="Amount"
              name="amount"
              value={formData.amount || ""}
              onChange={handleChange}
              type="number"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="status"
              label="Status"
              name="status"
              value={formData.status || ""}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>
            Confirm Delete
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this bill?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDeleteSubmit}
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 1 }}
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal open={openAddModal} onClose={handleCloseAddModal} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>
            Add Bill
          </Typography>
          <Box component="form" onSubmit={handleAddSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="clientName"
              label="Client Name"
              name="clientName"
              value={formData.clientName || ""}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email || ""}
              onChange={handleChange}
              type="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber || ""}
              onChange={handleChange}
              type="tel"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="amount"
              label="Amount"
              name="amount"
              value={formData.amount || ""}
              onChange={handleChange}
              type="number"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="status"
              label="Status"
              name="status"
              value={formData.status || ""}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={Boolean(error)} onClose={handleCloseError}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1">
            {error}
          </Typography>
          <Button onClick={handleCloseError} color="primary">
            OK
          </Button>
        </Box>
      </Modal>

      <Modal open={Boolean(success)} onClose={handleCloseSuccess}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Success
          </Typography>
          <Typography variant="body1">
            {success}
          </Typography>
          <Button onClick={handleCloseSuccess} color="primary">
            OK
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default ServiceProviderBills;
