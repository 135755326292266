import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Breadcrumbs,
  Link,
  IconButton,
  Badge,
  useMediaQuery
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faTasks,
  faDollarSign,
  faComments,
  faUserMd,
  faCartPlus,
  faMapMarkerAlt,
  faEnvelopeOpenText,
  faCalendarCheck,
  faFileAlt,
  faUserCircle,
  faArrowLeft,
  faSignOutAlt,
  faCog,
  faQuestionCircle,
  faMap,
  faCommentDots,
  faRecycle
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import UserAppointment from "../components/UserAppointment";
import UserAssessment from "../components/UserAssessment";
import UserSessions from "../components/UserSessions";
import UserTreatments from "../components/UserTreatments";
import UserPostRecovery from "../components/UserPostRecovery";
import UserSubscriptions from "../components/UserSubscriptions";
import UserBilling from "./UserBilling";
import UserSettings from "./UserSettings";
import { UserContext } from "../UserContext"; // Import UserContext
import axios from "axios";

const UserInbox = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(UserContext); // Access user and logout
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [mainMenu, setMainMenu] = useState(true); // Track if we are on the main menu
  const [subMenu, setSubMenu] = useState(null); // Track if a sub-menu is selected
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("Home");
  const [badgeCounts, setBadgeCounts] = useState({});

  const icons = [
    { name: "Appointments", icon: faCalendarAlt, component: 'Appointments', badgeCount: 5 },
    { name: "Responses", icon: faComments, component: 'Responses', badgeCount: 3 },
    { name: "Billing", icon: faDollarSign, component: 'Billing', badgeCount: 7 },
    { name: "Sessions", icon: faUserMd, component: 'Sessions', badgeCount: 2 },
    { name: "Treatments", icon: faTasks, component: 'Treatments', badgeCount: 4 },
    { name: "Post Recovery", icon: faCalendarCheck, component: 'PostRecovery', badgeCount: 6 },
    { name: "Assessments", icon: faFileAlt, component: 'Assessments', badgeCount: 3 },
    { name: "Feedback", icon: faCommentDots, component: 'Feedback', badgeCount: 4 },
    { name: "Our Shop", icon: faCartPlus, component: 'OurShop', badgeCount: 6 },
    { name: "Calendar", icon: faCalendarAlt, component: 'Calendar', badgeCount: 2 },
    { name: "Map", icon: faMap, component: 'Map', badgeCount: 0 },
    { name: "Chat", icon: faEnvelopeOpenText, component: 'Chat', badgeCount: 5 },
    { name: "Help", icon: faQuestionCircle, component: 'Help', badgeCount: 0 },
    { name: "Settings", icon: faCog, component: 'Settings', badgeCount: 0 },
    { name: "Logout", icon: faSignOutAlt, component: 'Logout', badgeCount: 0 },
  ];

  useEffect(() => {
    setLoading(false);  // Simulating loading finished
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
    setSnackbar({ open: true, message: 'Logged out successfully', severity: 'success' });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

// Define icons for the main menu
const mainMenuIcons = [
  { name: "Therapy", icon: faUserMd, subMenu: "therapy" },
  { name: "Financials", icon: faDollarSign, subMenu: "financials" },
  { name: "Settings", icon: faCog, component: "Settings" },
];

// Define sub-menu icons for Therapy
const therapySubMenuIcons = [
  { name: "Appointments", icon: faCalendarAlt, component: "Appointments" },
  { name: "Assessments", icon: faFileAlt, component: "Assessments" },
  { name: "Sessions", icon: faFileAlt, component: "Sessions" },
];

// Define sub-menu icons for Financials
const financialSubMenuIcons = [{ name: "Billing", icon: faDollarSign, component: "Billing" }];

// Define sub-menu icons for Administration
const settingsSubMenuIcons = [
  { name: "Settings", icon: faCog, component: "Settings" },
  
];

 // Media query to detect mobile view
 const isMobile = useMediaQuery("(max-width:600px)");

 // Fetch badge counts when the sub-menu loads
 useEffect(() => {
  const fetchBadgeCounts = async () => {
    const components = ["Appointments", "Responses", "Sessions", "PostRecovery", "Questionnaire", "Billing", "Subscriptions", "ResourceCenters", "Maps", "Ticketing", "Blog","Clients", "UserAccounts"];
    const counts = {};

    for (const component of components) {
      counts[component] = await getBadgeCount(component); // Fetch badge count for each component
    }

    setBadgeCounts(counts); // Update state with fetched counts
  };

  if (subMenu) fetchBadgeCounts();
}, [subMenu]); // Refetch counts whenever the sub-menu changes


const getBadgeCount = async (component) => {
  const endpoints = {
    Appointments: `https://api.cope.ke/appointments/${user.user_id}`,
    Responses: `https://api.cope.ke/assessments/${user.user_id}`,
    Treatments: "https://api.cope.ke/treatment",
    PostRecovery: "https://api.cope.ke/post_recoveries",
    Questionnaire: "https://api.cope.ke/questions",
    Billing: `https://api.cope.ke/user-billing/${user.user_id}`,
    Subscriptions: "https://api.cope.ke/subscriptions",
    ResourceCenters: "https://api.cope.ke/api/resource-centres",
    Maps: "https://api.cope.ke/maps",
    Ticketing: "https://api.cope.ke/tickets",
    Blog: "https://api.cope.ke/blogposts",
    Clients: "https://api.cope.ke/service_providers",
    UserAccounts: "https://api.cope.ke/api/accounts",
  };

  const url = endpoints[component];

  if (!url) return 0; // Return 0 if component does not match

  try {
    const response = await axios.get(url);

    // Assuming the response contains an array of pending items
    return response.data.length;
  } catch (error) {
    console.error(`Error fetching badge count for ${component}:`, error);
    return 0; // Return 0 on error
  }
};


// Handle navigation back to main menu
const handleBackToMainMenu = () => {
  if (selectedComponent) {
    setSelectedComponent(null); // Go back to the sub-menu
  } else if (subMenu) {
    setSubMenu(null); // Go back to the main menu
    setMainMenu(true);
    setBreadcrumbTitle("Home");
  }
};

// Handle click on main menu or sub-menu icons
const handleMainMenuClick = (menu) => {
  if (menu === "Logout") {
    handleLogout();
  } else if (menu === "therapy" || menu === "financials" || menu === "settings") {
    setSubMenu(menu);
    setMainMenu(false);
    setBreadcrumbTitle(menu.charAt(0).toUpperCase() + menu.slice(1)); // Set breadcrumb title dynamically
  } else {
    setSelectedComponent(menu);
    setMainMenu(false);
    setBreadcrumbTitle(menu); // Set the breadcrumb to component name
  }
};

const dynamicSubMenu = (menu, selectedComponent) => {
  const linkStyle = {
    cursor: "pointer",
    textDecoration: "none",
    color: "primary.main",
    fontWeight: "bold",
    padding: "8px 16px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#F9A64F",
      color: "#fff",
    },
  };

  const getFilteredIcons = (icons) =>
    icons.filter((icon) => icon.component !== selectedComponent);

  const renderMenuItems = (icons) =>
    icons.map((icon) => (
      <Box
        key={icon.name}
        onClick={() => setSelectedComponent(icon.component)}
        sx={{
          ...linkStyle,
          border: "1px solid teal",
          padding: "12px",
        }}
      >
        <FontAwesomeIcon icon={icon.icon} />
        <Typography>{icon.name}</Typography>
        <Badge
          badgeContent={badgeCounts[icon.component] || 0} // Use fetched badge count
          color="secondary"
          sx={{ ml: "auto" }}
        />
      </Box>
    ));

  switch (menu) {
    case "therapy":
      return renderMenuItems(getFilteredIcons(therapySubMenuIcons));
    case "financials":
      return renderMenuItems(getFilteredIcons(financialSubMenuIcons));
    case "settings":
      return renderMenuItems(getFilteredIcons(settingsSubMenuIcons));
    default:
      return null;
  }
};


  // Render icons based on whether we are in main menu or sub-menu
  const renderIcons = (icons) => (
    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 4 }}>
      {icons.map((icon, index) => (
        <Box key={index} sx={{ textAlign: "center" }}>
          <IconButton
            onClick={() =>
              icon.subMenu
                ? handleMainMenuClick(icon.subMenu)
                : setSelectedComponent(icon.component)
            }
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={icon.icon} />
          </IconButton>
          <Typography variant="body2" sx={{fontWeight:'bold'}}>{icon.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Appointments':
        return <UserAppointment />;
      case 'Responses':
        return <UserAssessment />;
      case 'Billing':
        return <UserBilling />;
      case 'Sessions':
        return <UserSessions />;
      case 'Treatments':
        return <UserTreatments />;
      case 'PostRecovery':
        return <UserPostRecovery />;
      case 'Assessments':
        return <UserAssessment />;
      case 'Feedback':
        return <UserAssessment />;
      case 'OurShop':
        return <UserAssessment />;
      case 'Calendar':
        return <UserAssessment />;
      case 'Map':
        return <UserAssessment />;
      case 'Chat':
        return <UserAssessment />;
      case 'Help':
        return <UserAssessment />;
      case 'Settings':
        return <UserSettings />;
      default:
        return mainMenu ? renderIcons(mainMenuIcons) : null;
    }
  };

  return (
    <Container sx={{ mt: 8, mb: 4 }}>
     <Box sx={{ display: "flex", alignItems: "center", mb: 2, pt:2 }}>
      {!mainMenu && ( // Conditionally render back button
          <IconButton onClick={handleBackToMainMenu} sx={{ color: "teal" }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
        )}
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: "#2C1C18" }}>
  <Link
    underline="hover"
    color="inherit"
    onClick={() => {
      setSelectedComponent(null);
      setSubMenu(null);
      setMainMenu(true);
      setBreadcrumbTitle("Home");
    }}
    style={{ cursor: "pointer" }}
  >
    Home
  </Link>
  {subMenu && (
    <Link
      underline="hover"
      color="inherit"
      onClick={() => {
        setSelectedComponent(null); // Clear selected component
        setMainMenu(false); // Stay in sub-menu
        setBreadcrumbTitle(subMenu.charAt(0).toUpperCase() + subMenu.slice(1));
      }}
      style={{ cursor: "pointer" }}
    >
      {breadcrumbTitle}
    </Link>
  )}
  {selectedComponent && (
    <Typography color="text.primary">{selectedComponent}</Typography>
  )}
</Breadcrumbs>

      <Box sx={{ ml: 2, display: isMobile ? 'none' : 'flex', alignItems: 'center', gap: 2, }}>{subMenu && dynamicSubMenu(subMenu)}</Box>
      </Box>

      {/* Render Main Menu or Sub Menu */}
      {mainMenu && !selectedComponent && renderIcons(mainMenuIcons)}

      {/* Sub Menu Rendering */}
      {!mainMenu && !selectedComponent && subMenu === "therapy" && (
        <>
          {renderIcons(therapySubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}
      {!mainMenu && !selectedComponent && subMenu === "financials" && (
        <>
          {renderIcons(financialSubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}

      {/* Render Selected Component */}
      {selectedComponent && (
        <Box sx={{ mt: 4, mb: 4 }}>
          {renderComponent()}
          <Box sx={{ textAlign: "center", my: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </Box>
      )}

      {/* Centered Logout Button */}
      {mainMenu && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <IconButton
            onClick={handleLogout}
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
          </IconButton>
          <Typography color="#F9A64F" variant="body2" sx={{fontWeight:"bold"}}>Logout</Typography>
        </Box>
      )}

      {/* Snackbar for feedback messages */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ open: false })}>
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default UserInbox;
