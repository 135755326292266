import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Box,  Grid, Typography, Chip, Card, CardContent, Button, TextField, CircularProgress, Modal, Snackbar, Alert, Table, TableBody, TableCell, TableRow, TableHead, TableContainer, Paper, IconButton, TimelineContainer, Container, Breadcrumbs, Link } from '@mui/material';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/CheckCircle';
import FlagIcon from '@mui/icons-material/Flag';
import PuzzleIcon from '@mui/icons-material/Extension';
import RocketIcon from '@mui/icons-material/RocketLaunch';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';

import Navbar from '../../components/Navbar';
import Hero from '../../components/Hero';
import Footer from '../../components/Footer';
import WebAccessibility from '../../components/WebAccessibility';
import ScrollTopFab from '../../components/ScrollTopFab';

import serviceImage from '../../assets/img2.png';
import therapistImage from '../../assets/img8.png';
import childImage from '../../assets/img1.png';
import selfCareLadyWithPhoneImage from '../../assets/img10.png';
import wellnessHubImage from '../../assets/img6.jpg';
import wellnessProgramImage from '../../assets/img4.jpg';

import therapyImage1 from '../../assets/session1.jpg';
import therapyImage2 from '../../assets/session2.jpg';
import therapyImage3 from '../../assets/session3.jpg';

import Corporates from '../Corporates';

const FlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '20px',
  color: '#333',
  width: '100%', 

  // Stack elements vertically on smaller screens with image above text
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '30px 10px',
  },
}));

// Styled Breadcrumbs
const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: '#f5f5f5',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
}));

const BlendedImage = styled(Box)(({ theme, backgroundImage }) => ({
  flex: '1',
  height: '400px',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundBlendMode: 'multiply',
  opacity: '0.85',
  backgroundColor: '#f5f5f5',
  borderRadius: '10px',
  width: '100%',  
  [theme.breakpoints.down('sm')]: {
    order: -1, // Ensures image shows first
    height: '250px',
    minHeight: '250px',
    marginBottom: '15px',
  },
}));

const BlendedImage2 = styled(Box)(({ theme, backgroundImage }) => ({
  flex: '1',
  height: '400px',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundBlendMode: 'multiply',
  backgroundColor: '#f5f5f5',
  borderRadius: '10px',
  width: '100%',
  position: 'relative', // Allows the overlay text to position correctly over the image
  display: 'flex', // Centers the text container
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    height: '300px', // Adjust height for mobile
  },
}));

const TextOverlay = styled(Box)(({ theme }) => ({
  color: 'white',
  textAlign: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adds a translucent background for better contrast
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px', // Reduce font size for mobile
    padding: theme.spacing(1),
    width: '90%', // Adjust width for mobile
  },
}));
const TextContent = styled(Box)(({ theme }) => ({
  flex: '1',
  textAlign: 'center',
  padding: '20px',

  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
}));


// Styling
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const steps = [
  {
    title: 'It all starts with you.',
    description: 'You sign up on Cope, sharing a little about yourself and your mental health needs. From there, we take care of the rest.',
    icon: <FlagIcon sx={{ fontSize: '60px', color: '#fff' }} />,
  },
  {
    title: 'Next, we find your match.',
    description: 'Using the information you’ve provided, Cope connects you with a therapist who specializes in the areas you’re seeking help with.',
    icon: <PuzzleIcon sx={{ fontSize: '60px', color: '#fff' }} />,
  },
  {
    title: 'It’s time to take the first step...',
    description: 'You can easily book a session, choosing between an in-person meeting or the comfort of an online session here.',
    icon: <RocketIcon sx={{ fontSize: '60px', color: '#fff' }} />,
  },
  {
    title: 'And it doesn’t stop there...',
    description: 'After each session, you’ll receive therapist notes, personalized recommendations, and exercises to track your progress as you grow.',
    icon: <CheckIcon sx={{ fontSize: '60px', color: '#fff' }} />,
  },
];


// Main component function
const Homepage = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '' });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [openTherapistModal, setOpenTherapistModal] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [selectedTherapistId, setSelectedTherapistId] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [activePage, setPage] = useState('Home');
  const [showCorporate, setShowCorporate] = useState(false)
  const [showWellnessHub, setShowWellnessHub] = useState(false);
  const [showSelfCare, setShowSelfCare] = useState(false);
  const [merchandise, setMerchandise] = useState([]);
  const [loadingMerchandise, setLoadingMerchandise] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenTherapistModal = () => setOpenTherapistModal(true);
  const handleCloseTherapistModal = () => setOpenTherapistModal(false);
  const handleBookClick = (therapistId) => {
    setSelectedTherapistId(therapistId);
    setOpenBookingModal(true);
  };

  // Fetch wellness programs
useEffect(() => {
  const fetchPrograms = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.cope.ke/subscriptions');
      // Filter out programs with category === 'admin'
      const filteredPrograms = response.data.filter(program => program.category !== 'admin');
      setPrograms(filteredPrograms);
    } catch (error) {
      console.error('Error fetching wellness programs:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchPrograms();
}, []);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  // Handle card click
  const handleCardClick = (program) => {
    setSelectedProgram(program);
  };

  const handleExplore = () => navigate('./corporates');

  const handleSectionClick = (section) => {
    setPage(section);
    window.scrollTo(0, 0); // Scrolls to the top on section navigation
  };

  if (showCorporate) return <Corporates />;

  const handleBackClick = () => {
    if (activePage === 'Self-Care Products' || activePage === 'Wellness Programs') {
      setPage('Wellness Hub');
    } else {
      setPage('Home');
    }
    window.scrollTo(0, 0); // Scrolls to the top on back navigation
  };

  const handleWellnessClick = () => {
    setShowWellnessHub(true);
    setPage('Wellness Hub');
  };

  const handleSelfCareClick = async () => {
    
    setShowSelfCare(true);
    setPage('Self Care');
    // Fetch merchandise for self-care
    try {
      const response = await axios.get('https://api.cope.ke/merchandise');
      setMerchandise(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to load merchandise items', severity: 'error' });
    }
  };



  

  // Breadcrumbs with a back button if not on the Home page
  const renderBreadcrumbs = () => (
    activePage !== 'Home' && (
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
        <IconButton sx={{color:'teal'}} onClick={handleBackClick} size="small">
          <ArrowBackIcon />
        </IconButton>
        <Typography color="text.primary">{activePage}</Typography>
      </Breadcrumbs>
    )
  );

    // Fetch Merchandise when SelfCare is clicked
    const fetchMerchandise = async () => {
      setLoadingMerchandise(true);
      try {
        const response = await axios.get('https://api.cope.ke/merchandise');
        setMerchandise(response.data);
      } catch (error) {
        setSnackbar({ open: true, message: 'Error fetching merchandise', severity: 'error' });
      } finally {
        setLoadingMerchandise(false);
      }
    };

    const handleExploreSelfCare = () => {
      fetchMerchandise();
      setPage('Self-Care Products');
    };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.post('https://api.cope.ke/book_appointment', formData);
      setSnackbar({ open: true, message: 'Appointment booked successfully!', severity: 'success' });
      handleCloseModal();
    } catch (error) {
      setSnackbar({ open: true, message: 'Booking failed. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitBooking = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://api.cope.ke/therapy_appointment', {
        name: userName,
        email: userEmail,
        phone: userPhone,
        provider_id: selectedTherapistId, 
        
      });
      setSnackbar({ open: true, message: 'Appointment booked successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to book appointment', severity: 'error' });
    } finally {
      setLoading(false);
      setOpenBookingModal(false);
    }
  };

  // Handle search for therapists
  const handleSearch = async (keyword) => {
    setSearchKeyword(keyword);
    setLoading(true);
    setNoResults(false);

    try {
      const response = await axios.get('https://api.cope.ke/service_providers');
      const regex = new RegExp(keyword, 'i');
      const filteredResults = response.data.filter((therapist) =>
        regex.test(therapist.company_name) || regex.test(therapist.specialization)
      );
      if (filteredResults.length > 0) {
        setSearchResults(filteredResults);
      } else {
        setNoResults(true);
        setSearchResults([]);
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Error occurred while searching for therapists', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Styling for the vertical timeline
const TimelineContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '20px 0',
  
  color: '#fff',
  textAlign: 'center',
}));

const VerticalLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '0',
  width: '4px',
  height: '100%',
  backgroundColor: '#e46e24',
  [theme.breakpoints.down('md')]: {
    left: '10%',
  },
}));

const CircleDot = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  backgroundColor: '#e46e24',
  borderRadius: '50%',
  position: 'absolute',
  left: 'calc(50% - 10px)',
  [theme.breakpoints.down('md')]: {
    left: 'calc(10% - 10px)',
  },
}));

const StepBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  color: '#2C1C18',
  width: '90%',
  borderRadius: '8px',
  textAlign: 'center',
  marginBottom: '30px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
  },
}));

// Helmet SEO titles based on page
const renderHelmet = () => {
  let title, description;
  switch (activePage) {
    case 'Blog':
      title = 'Blog - Mental Health Insights';
      description = 'Latest mental health insights and articles.';
      break;
    case 'Contact':
      title = 'Contact Us - Cope Wellness';
      description = 'Get in touch with our support team.';
      break;
    case 'Terms':
      title = 'Terms and Conditions - Cope Wellness';
      description = 'Our terms and conditions for using Cope services.';
      break;
    case 'Policy':
      title = 'Privacy Policy - Cope Wellness';
      description = 'Learn about our privacy policies.';
      break;
    default:
      title = 'Your Mental Wellness Journey Starts Here - Cope Wellness';
      description = 'Cope is an online platform providing mental health services.';
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};


  return (
      <div>
        <Navbar />
        {renderHelmet()}
        {renderBreadcrumbs()}
      <Container>
        {activePage === 'Home' && (
        <>
        <Hero />
              {/* Section with Image and Text Side by Side */}
            <FlexContainer>
          <TextContent>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Take the First Step to <span style={{ color: '#1a6051' }}>Wellness</span> Today.</Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', fontSize: '18px', color:'#333' }}>
              Don't wait. Start your journey to better mental health with a therapist who understands you. Tailored support is just a click away.
            </Typography>
            <Button onClick={() => handleSectionClick('Wellness Hub')} variant="contained" sx={{ padding: '10px 20px' }}>
                Wellness Hub
            </Button>
          </TextContent>
          <BlendedImage backgroundImage={serviceImage} />
        </FlexContainer>

        {/* Section for Therapy Image and Text Side by Side */}
        <FlexContainer>
          <BlendedImage backgroundImage={therapistImage} />
          <TextContent>
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px', color: '#333' }}>
              With <span style={{ color: '#1a6051' }}>Cope</span>, Connect Your Organization with Top Certified Professionals
            </Typography>
            <Typography variant="body1" sx={{ color: '#666', marginBottom: '20px' }}>
            Corporates can now access customized wellness programs for their staff, offering secure,
            confidential support through personalized health sessions. Elevate your team’s well-being today!
            </Typography>
            <Button onClick={handleExplore} variant="contained" sx={{ backgroundColor: '#1a6051', color: '#fff', padding: '10px 20px' }}>
              Explore
            </Button>
          </TextContent>
        </FlexContainer>
        
        </>
        )}
        {/* Wellness Hub Section */}
      {activePage === 'Wellness Hub' && (
        <>
        <Grid container spacing={3} alignItems="start" sx={{mb: 4}}>
         {/* Image Column - Adjusted to show first on mobile */}
          <Grid item xs={12} md={4} sx={{ order: { xs: -1, md: 1 } }}>
            <BlendedImage backgroundImage={wellnessHubImage} />
          </Grid>
          <Grid item xs={12} md={8}>
          <FlexContainer>
          <TextContent>
            <Typography variant="h4" sx={{ mb: 3, color: '#1a6051' }}>Our Wellness Hub</Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
            Discover a range of wellness programs crafted to support both physical and mental well-being. Our Wellness Hub offers a variety of activities—from fitness and sports to group meet-ups and engaging workshops—that are designed to build resilience, reduce stress, and foster a sense of community. <br/><br/> Whether you’re looking to stay active, connect with others, or explore new self-care practices, the Wellness Hub has something for everyone.
            </Typography>
            <Button variant="contained" onClick={() => handleSectionClick('Wellness Programs')} sx={{ backgroundColor: '#00695C', color: '#fff', mb: 2 }}>
              Explore
            </Button>
          </TextContent>
          
          
        </FlexContainer>
          </Grid> 
          

        </Grid>
        <Grid container spacing={3} alignItems="start" sx={{mb: 4}}>

          <Grid item xs={12} md={4} >
          <BlendedImage backgroundImage={selfCareLadyWithPhoneImage} />
          </Grid> 
          <Grid item xs={12} md={8}>
          <FlexContainer>
          <TextContent>
            <Typography variant="h4" sx={{ mb: 3, color: '#1a6051' }}>Our Self Care Hub</Typography>
            
            <Typography variant="body1" sx={{ mb: 4 }}>
            Taking time for self-care is essential to managing stress, enhancing focus, and maintaining balance. Our Self-Care Hub includes personalized self-care suggestions that allow you to choose what works best for you. <br/><br/> Our activities are crafted to fit into any lifestyle, offering flexible, enjoyable ways to recharge and support mental wellness.
            </Typography>
            <Button variant="contained" onClick={() => handleSectionClick('Self-Care Products')} sx={{ mb: 2 }}>
              Explore
            </Button>
          </TextContent>         
          
        </FlexContainer>
          </Grid>  

        </Grid>
        </>
      )}

      {/* Wellness Details Section */}
      {activePage === 'Wellness Programs' && (
        <>
        <FlexContainer>
        <BlendedImage2 backgroundImage={wellnessProgramImage}>
        <TextOverlay variant="h4">
        <TextContent>
          <Typography variant="h4" sx={{ color: '#fff' }}>Our Wellness Programs</Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Engage in activities like sports, team building, workshops, and other group events designed to foster personal growth and community well-being.
          </Typography>
        </TextContent>
      </TextOverlay>
        </BlendedImage2>
    
      </FlexContainer>
      <div style={{mt:3, mb: 3}}>
      {loading ? (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {programs.map((program) => (
            <Grid item xs={12} sm={6} md={4} key={program.id}>
              <Card
                onClick={() => handleCardClick(program)}
                sx={{
                  cursor: 'pointer',
                  transition: 'transform 0.3s',
                  '&:hover': { transform: 'scale(1.05)' },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ color: 'teal', fontWeight: 'bold' }}
                  >
                    {program.plan}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: '#666', marginBottom: 2 }}
                  >
                    {program.description}
                  </Typography>
                  <Chip
                    label={program.category || 'General'} // Replace `category` with your API key for the category
                    sx={{ backgroundColor: '#e0f7fa', color: '#00796b' }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

    </div>
      </>
      )}

      {/* Self-Care Section */}
      {activePage === 'Self-Care Products' && (
        <FlexContainer>
        <BlendedImage backgroundImage={therapistImage} />
        <TextContent>
          <Typography variant="h4" sx={{ color: '#1a6051' }}>Self-Care Products</Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Explore our curated collection of self-care merchandise to help you maintain a balanced lifestyle.
          </Typography>
          
        </TextContent>
      </FlexContainer>
      )}

      {/* Self-Care Details Section (Merchandise List) */}
      {activePage === 'SelfCareDetails' && (
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" sx={{ color: '#1a6051', textAlign: 'center' }}>Available Merchandise</Typography>
          {loadingMerchandise ? (
            <Typography>Loading merchandise...</Typography>
          ) : (
            merchandise.map((item) => (
              <Box key={item.id} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
                <Typography variant="h6">{item.name}</Typography>
                <Typography>{item.description}</Typography>
                <Typography>Price: ${item.price}</Typography>
              </Box>
            ))
          )}
        </Box>
      )}

      {/* Modal for Booking Appointment */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center', color: '#00695C' }}>Book an Appointment</Typography>
          <TextField required id="name" label="Your Name" name="name" InputLabelProps={{ style: { color: '#2C1C18' } }} value={formData.name} onChange={handleChange} fullWidth sx={{ marginBottom: 2 }} />
          <TextField required id="email" label="Your Email" name="email" InputLabelProps={{ style: { color: '#2C1C18' } }} value={formData.email} onChange={handleChange} type="email" fullWidth sx={{ marginBottom: 2 }} />
          <TextField label="Phone Number" name="phone" value={formData.phone} InputLabelProps={{ style: { color: '#2C1C18' } }} onChange={handleChange} fullWidth sx={{ marginBottom: '20px' }} />
          <Button variant="contained" sx={{ backgroundColor: '#e46e24', width: '100%', color: '#ffffff' }} onClick={handleSubmit} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Confirm Appointment'}
          </Button>
        </Box>
      </Modal>

      {/* Therapist Search Modal */}
      <Modal open={openTherapistModal} onClose={handleCloseTherapistModal}>
        <Box sx={modalStyle}>
          <Typography color='00695C' variant="h6" sx={{ mb: 2 }}>Search for a Therapist</Typography>
          <TextField  label="Search by name or specialization" InputLabelProps={{ style: { color: '#2C1C18' } }}  value={searchKeyword} onChange={(e) => handleSearch(e.target.value)} fullWidth sx={{ mb: 2 }} />
          {loading && <Box sx={{ textAlign: 'center', mt: 2 }}><CircularProgress /></Box>}
          {noResults && !loading && <Typography sx={{ mt: 2, textAlign: 'center', color: 'red' }}>No records found</Typography>}
          {searchResults.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', color: '#00695C' }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#00695C' }}>Specialization</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#00695C' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResults.map((therapist) => (
                    <TableRow key={therapist.provider_id}>
                      <TableCell>{therapist.company_name}</TableCell>
                      <TableCell>{therapist.specialization}</TableCell>
                      <TableCell>
                        <Button variant="contained" color="primary" onClick={() => handleBookClick(therapist.provider_id)}>Book</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Modal>

      {/* Appointment Booking Modal */}
      <Modal open={openBookingModal} onClose={() => setOpenBookingModal(false)}>
        <Box sx={modalStyle}>
          <IconButton onClick={() => setOpenBookingModal(false)} sx={{ position: 'absolute', top: 8, right: 8, color: '#00695C' }}><CloseIcon /></IconButton>
          <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center', color: '#00695C' }}>Book Appointment</Typography>
          <TextField label="Name" InputLabelProps={{ style: { color: '#2C1C18' } }} value={userName} onChange={(e) => setUserName(e.target.value)} fullWidth sx={{ marginBottom: '20px' }} />
          <TextField label="Email" InputLabelProps={{ style: { color: '#2C1C18' } }} value={userEmail} onChange={(e) => setUserEmail(e.target.value)} fullWidth sx={{ marginBottom: '20px' }} />
          <TextField label="Phone Number" InputLabelProps={{ style: { color: '#2C1C18' } }} value={userPhone} onChange={(e) => setUserPhone(e.target.value)} fullWidth sx={{ marginBottom: '20px' }} />
          <Button variant="contained" sx={{ backgroundColor: '#00695C', color: '#fff' }} onClick={handleSubmitBooking}>Book Appointment</Button>
        </Box>
      </Modal>

      {/* Snackbar for feedback */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>

      <ScrollTopFab />
      </Container>
      </div>
  );
};

export default Homepage;
