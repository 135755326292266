import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Avatar,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  Modal
} from "@mui/material";
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import axios from "axios";

const SuperuserSubscriptions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]); // State for subscription plans
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [formData, setFormData] = useState({ name: "", description: "", items: [], isService: true });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [modalType, setModalType] = useState("add"); 
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchSubscriptions();
    fetchSubscriptionPlans(); // Fetch subscription plans
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/subscriptions");
      setSubscriptions(response.data);
      setFilteredSubscriptions(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch subscriptions data", severity: "error" });
      setLoading(false);
    }
  };

  const fetchSubscriptionPlans = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/subscription-plan");
      setSubscriptionPlans(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch subscription plans", severity: "error" });
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSubscriptions(
      subscriptions.filter(
        (subscription) =>
          subscription.plan.toLowerCase().includes(value) ||
          subscription.description.toLowerCase().includes(value)
      )
    );
  };

  const handleCardClick = (subscription = null) => {
    setSelectedSubscription(subscription);
    if (subscription) {
      setFormData({
        ...subscription,
        items: subscription.items || [],
        isService: subscription.isService !== undefined ? subscription.isService : true,
      });
    } else {
      setFormData({ name: "", description: "", items: [], isService: true });
    }
  };

  const handleOpenModal = (type, subscription = null) => {
    if (type === "edit" || type === "view") {
      setSelectedSubscription(subscription);
      setFormData(subscription);
    } else {
      setFormData({ name: "", description: "" }); // Reset formData
    }
    setModalType(type);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleAddSubmit = async () => {
    setIsSubmitting(true);
    try {
      // POST request to add a new subscription plan
      const response = await axios.post("https://api.cope.ke/subscriptions", formData);

      setSnackbar({ open: true, message: "Service added successfully", severity: "success" });
      handleModalClose()
      // Refresh the subscriptions after successful addition
      fetchSubscriptions();
      setSelectedSubscription(null); // Clear selected subscription
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Show an error if the plan_type/item.name already exists
        setSnackbar({ open: true, message: "Item with this name already exists", severity: "error" });
      } else {
        setSnackbar({ open: true, message: "Failed to save subscription", severity: "error" });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/subscriptions/${selectedSubscription.id}`, formData);
      fetchSubscriptions();
      setOpenModal(false);
      setSnackbar({ open: true, message: "Subscription updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update subscription", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleBackClick = () => {
    setSelectedSubscription(null); // Go back to the list view
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddItem = () => {
    setFormData({
      ...formData,
      items: [...formData.items, { name: "", price: 0, duration: "", qty: "", delivery: "" }],
    });
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = formData.items.map((item, idx) => {
      if (idx === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setFormData({ ...formData, items: updatedItems });
  };

  const handleServiceSwitch = (event) => {
    setFormData({ ...formData, isService: event.target.checked });
  };

  // Handler to edit a subscription plan
  const handleEdit = (plan) => {
    setFormData({
      plan_type: plan.plan_type,
      price: plan.price,
      duration: plan.duration,
      description: plan.description,
    });
    setSelectedSubscription(plan);
  };

  // Handler to delete a subscription plan
  const handleDelete = async (plan_id) => {
    try {
      await axios.delete(`https://api.cope.ke/subscription-plan/${plan_id}`);
      setSnackbar({ open: true, message: "Plan deleted successfully", severity: "success" });
      fetchSubscriptionPlans(); // Refresh the plans after deletion
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete plan", severity: "error" });
    }
  };

  // Modify renderTable to display fetched subscription plans with action column
  const renderTable = () => (
    <Table sx={{ mt: 4 }}>
      <TableHead>
        <TableRow>
          <TableCell>Plan Name</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Duration</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subscriptionPlans && subscriptionPlans.length > 0 ? (
          subscriptionPlans.map((plan, index) => (
            <TableRow key={index}>
              <TableCell>{plan.plan_type}</TableCell>
              <TableCell>{plan.price}</TableCell>
              <TableCell>{plan.duration}</TableCell>
              <TableCell>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleOpenModal("edit", plan)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(plan.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={3} align="center">
              No subscription plans available.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
  
  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredSubscriptions.map((subscription) => (
        <Card key={subscription.plan_id} sx={{ width: 300, m: 2 }} onClick={() => handleCardClick(subscription)}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar>{subscription.plan ? subscription.plan.charAt(0) : "?"}</Avatar>
              <Box>
                <Tooltip title="Edit">
                  <IconButton sx={{ color: "#00695C" }}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton sx={{ color: "#00695C" }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{subscription.plan}</Typography>
            <Typography variant="body2">{subscription.description}</Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  const renderModalContent = () => {
    switch (modalType) {
      case "add":
        return (
          <>
            <Typography variant="h6" gutterBottom>Add Subscription</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleFormChange}
              sx={{ backgroundColor: "#fff", borderRadius: 1 }}
              InputLabelProps={{ style: { color: '#2C1C18' } }}
            />
            <TextField
              margin="normal"
              fullWidth
              id="description"
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleFormChange}
              sx={{ backgroundColor: "#fff", borderRadius: 1 }}
              InputLabelProps={{ style: { color: '#2C1C18' } }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleAddSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Add"}
            </Button>
          </>
        );
      case "edit":
        return (
          <>
            <Typography variant="h6" gutterBottom>Edit Subscription</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleFormChange}
              sx={{ backgroundColor: "#fff", borderRadius: 1 }}
            />
            <TextField
              margin="normal"
              fullWidth
              id="description"
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleFormChange}
              sx={{ backgroundColor: "#fff", borderRadius: 1 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleEditSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Update"}
            </Button>
          </>
        );
      case "view":
        return (
          <>
            <Typography variant="h6" gutterBottom>View Subscription</Typography>
            <Typography variant="body1">Name: {selectedSubscription.plan}</Typography>
            <Typography variant="body1">Description: {selectedSubscription.description}</Typography>
          </>
        );
      case "delete":
        return (
          <>
            <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
            <Typography variant="body1" gutterBottom>
              Are you sure you want to delete the subscription "{selectedSubscription.plan}"?
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              sx={{ mt: 2 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container  sx={{ mt: 4, mb: 4 }}>
      {selectedSubscription ? (
        <>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBackClick}
            sx={{ marginBottom: 2 }}
          >
            Back
          </Button>
          <Typography variant="h4" gutterBottom>Manage Subscription</Typography>
          <Box component="form" noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="plan"
              label="Plan Name"
              name="plan"
              value={formData.plan}
              onChange={handleFormChange}
              sx={{ backgroundColor: "#fff", borderRadius: 1 }}
            />
            <TextField
              margin="normal"
              fullWidth
              id="description"
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleFormChange}
              sx={{ backgroundColor: "#fff", borderRadius: 1 }}
            />

            {/* MUI Switch for Product/Service */}
            <FormControlLabel
              control={<Switch checked={formData.isService} onChange={handleServiceSwitch} />}
              label={formData.isService ? "Service" : "Product"}
              sx={{ mb: 3 }}
            />

            <Button variant="contained" color="primary" onClick={handleAddItem} sx={{ mt: 3 }}>
              Add Item
            </Button>

            {renderTable()}

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitting}
                onClick={handleAddSubmit}
              >
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Save"}
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h4" gutterBottom>Manage Subscriptions</Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search subscriptions"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              sx={{ mr: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => handleOpenModal("add")}
              sx={{ padding: isMobile ? "8px 12px" : "8px 16px" }}
            >
              Add Subscription
            </Button>
          </Box>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : filteredSubscriptions.length > 0 ? (
            renderCards()
          ) : (
            <Typography variant="body1" sx={{ mt: 4 }}>
              No subscriptions found.
            </Typography>
          )}
        </>
      )}

      <Modal open={openModal} onClose={handleModalClose} sx={{ overflowY: "auto" }}>
        <Box component="form" sx={{ ...modalStyle, width: 400 }} noValidate>
          {renderModalContent()}
        </Box>
      </Modal>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "#F5F5F5", // Updated background color
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserSubscriptions;
