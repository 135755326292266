import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Breadcrumbs,
  Link,
  IconButton,
  Badge,
  useMediaQuery
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTasks, faDollarSign, faUsers, faComments, faUserMd, faCartPlus, faBlog, faMapMarkerAlt, faEnvelopeOpenText, faCalendarCheck, faFileAlt, faUserCircle, faArrowLeft, faSignOutAlt, faCog, faRecycle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import axios from "axios";

import ServiceProviderAppointments from "../components/ServiceProviderAppointments";
import ServiceProviderPostRecovery from "../components/ServiceProviderPostRecovery";
import ServiceProviderQuestionnaire from "../components/ServiceProviderQuestionnaire";
import ServiceProviderSettings from "../components/ServiceProviderSettings";
import ServiceProviderSubscriptions from "../components/ServiceProviderSubscriptions";
import ServiceProviderBills from "../components/ServiceProviderBills";
import ServiceProviderClients from "../components/ServiceProviderClients";
import ServiceProviderStaff from "./ServiceProviderStaff";
import ServiceProviderTherapyAssessments from "./ServiceProviderTherapyAssessments";
import ServiceProviderTherapySessions from "./ServiceProviderTherapySessions";
import ServiceProviderTherapyTreatments from "./ServiceProviderTherapyTreatments";
import ServiceProviderCalendar from "./ServiceProviderCalendar";

import { UserContext } from "../UserContext"; // Import UserContext


const ServiceProviderInbox = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(UserContext); // Access user and logout
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [subMenu, setSubMenu] = useState(null); // Track if a sub-menu is selected
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("Home");
  const [mainMenu, setMainMenu] = useState(true); // Track if we are on the main menu
  const [badgeCounts, setBadgeCounts] = useState({});

  const icons = [
    { name: "Appointments", icon: faCalendarAlt, component: 'Appointments', badgeCount: 5 },
    { name: "Responses", icon: faComments, component: 'Responses', badgeCount: 3 },
    { name: "Billing", icon: faDollarSign, component: 'Billing', badgeCount: 7 },
    { name: "Sessions", icon: faUserMd, component: 'Sessions', badgeCount: 2 },
    { name: "Treatments", icon: faTasks, component: 'Treatments', badgeCount: 4 },
    { name: "Post Recovery", icon: faCalendarCheck, component: 'PostRecovery', badgeCount: 6 },
    { name: "Assessments", icon: faFileAlt, component: 'Assessments', badgeCount: 3 },
    { name: "Tickets", icon: faEnvelopeOpenText, component: 'Tickets', badgeCount: 4 },
    { name: "Our Shop", icon: faCartPlus, component: 'OurShop', badgeCount: 6 },
    { name: "Inventory", icon: faTasks, component: 'Inventory', badgeCount: 1 },
    { name: "Calendar", icon: faCalendarAlt, component: 'Calendar', badgeCount: 2 },
    { name: "Clients", icon: faUsers, component: 'Clients', badgeCount: 7 },
    { name: "Teams", icon: faUsers, component: 'Teams', badgeCount: 1 },
    { name: "Settings", icon: faCog, component: 'Settings', badgeCount: 0 },
    { name: "Logout", icon: faSignOutAlt, component: 'Logout', badgeCount: 0 },
  ];

    // Define icons for the main menu
    const mainMenuIcons = [
      { name: "Therapy", icon: faUserMd, subMenu: "therapy" },
      { name: "Financials", icon: faDollarSign, subMenu: "financials" },
      { name: "Administration", icon: faUserCircle, subMenu: "administration" },
      { name: "Calendar", icon: faCalendarAlt, component: "Calendar" },
      { name: "Settings", icon: faCog, component: "Settings" },
    ];
  
    // Define sub-menu icons for Therapy
    const therapySubMenuIcons = [
      { name: "Appointments", icon: faCalendarAlt, component: "Appointments" },
      { name: "Responses", icon: faComments, component: "Responses" },
      { name: "Sessions", icon: faUserMd, component: "Sessions" },
      { name: "Post Recovery", icon: faRecycle, component: "PostRecovery" },
      { name: "Questionnaire", icon: faFileAlt, component: "Questionnaire" },
    ];
  
    // Define sub-menu icons for Financials
    const financialSubMenuIcons = [{ name: "Billing", icon: faDollarSign, component: "Billing" }];
  
    // Define sub-menu icons for Administration
    const adminSubMenuIcons = [
      { name: "Ticketing", icon: faEnvelopeOpenText, component: "Tickets" },
      { name: "Clients", icon: faUsers, component: "Clients" },
      { name: "Staff", icon: faUserCircle, component: "Teams" },
    ];

  useEffect(() => {
    setLoading(false);  // Simulating loading finished
  }, []);

   // Media query to detect mobile view
   const isMobile = useMediaQuery("(max-width:600px)");

   // Fetch badge counts when the sub-menu loads
  useEffect(() => {
    const fetchBadgeCounts = async () => {
      const components = ["Appointments", "Responses", "Treatments", "PostRecovery", "Questionnaire", "Billing", "Subscriptions", "ResourceCenters", "Maps", "Ticketing", "Blog","Clients", "UserAccounts"];
      const counts = {};
  
      for (const component of components) {
        counts[component] = await getBadgeCount(component); // Fetch badge count for each component
      }
  
      setBadgeCounts(counts); // Update state with fetched counts
    };
  
    if (subMenu) fetchBadgeCounts();
  }, [subMenu]); // Refetch counts whenever the sub-menu changes
  
  
  const getBadgeCount = async (component) => {
    const endpoints = {
      Appointments: `https://api.cope.ke/sp_appointments/${user.provider_id}`,
      Responses: `https://api.cope.ke/sp-responses/${user.provider_id}`,
      Sessions: `https://api.cope.ke/api/therapy-sessions/${user.provider_id}`,
      PostRecovery: `https://api.cope.ke/post_recoveries/${user.provider_id}`,
      Questionnaire: `https://api.cope.ke/sp-questions/${user.provider_id}`,
      Billing: `https://api.cope.ke/billing/${user.provider_id}`,
      Subscriptions: "https://api.cope.ke/subscriptions",
      ResourceCenters: "https://api.cope.ke/api/resource-centres",
      Maps: "https://api.cope.ke/maps",
      Ticketing: "https://api.cope.ke/tickets",
      Blog: "https://api.cope.ke/blogposts",
      Clients: "https://api.cope.ke/service_providers",
      UserAccounts: "https://api.cope.ke/api/accounts",
    };
  
    const url = endpoints[component];
  
    if (!url) return 0; // Return 0 if component does not match
  
    try {
      const response = await axios.get(url);
  
      // Assuming the response contains an array of pending items
      return response.data.length;
    } catch (error) {
      console.error(`Error fetching badge count for ${component}:`, error);
      return 0; // Return 0 on error
    }
  };
  

  const handleLogout = () => {
    logout(); // Call the logout function from the context
    navigate('/');
    setSnackbar({ open: true, message: 'Logged out successfully', severity: 'success' });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  // Handle navigation back to main menu
  const handleBackToMainMenu = () => {
    if (selectedComponent) {
      setSelectedComponent(null); // Go back to the sub-menu
    } else if (subMenu) {
      setSubMenu(null); // Go back to the main menu
      setMainMenu(true);
      setBreadcrumbTitle("Home");
    }
  };

  // Handle click on main menu or sub-menu icons
  const handleMainMenuClick = (menu) => {
    if (menu === "Logout") {
      handleLogout();
    } else if (menu === "therapy" || menu === "financials" || menu === "administration") {
      setSubMenu(menu);
      setMainMenu(false);
      setBreadcrumbTitle(menu.charAt(0).toUpperCase() + menu.slice(1)); // Set breadcrumb title dynamically
    } else {
      setSelectedComponent(menu);
      setMainMenu(false);
      setBreadcrumbTitle(menu); // Set the breadcrumb to component name
    }
  };

  const dynamicSubMenu = (menu, selectedComponent) => {
    const linkStyle = {
      cursor: "pointer",
      textDecoration: "none",
      color: "primary.main",
      fontWeight: "bold",
      padding: "8px 16px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      transition: "background-color 0.3s, color 0.3s",
      "&:hover": {
        backgroundColor: "#F9A64F",
        color: "#fff",
      },
    };
  
    const getFilteredIcons = (icons) =>
      icons.filter((icon) => icon.component !== selectedComponent);
  
    const renderMenuItems = (icons) =>
      icons.map((icon) => (
        <Box
          key={icon.name}
          onClick={() => setSelectedComponent(icon.component)}
          sx={{
            ...linkStyle,
            border: "1px solid teal",
            padding: "12px",
          }}
        >
          <FontAwesomeIcon icon={icon.icon} />
          <Typography>{icon.name}</Typography>
          <Badge
            badgeContent={badgeCounts[icon.component] || 0} // Use fetched badge count
            color="secondary"
            sx={{ ml: "auto" }}
          />
        </Box>
      ));
  
    switch (menu) {
      case "therapy":
        return renderMenuItems(getFilteredIcons(therapySubMenuIcons));
      case "financials":
        return renderMenuItems(getFilteredIcons(financialSubMenuIcons));
      case "administration":
        return renderMenuItems(getFilteredIcons(adminSubMenuIcons));
      default:
        return null;
    }
  };

  const renderIcons = (icons) => (
    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 4 }}>
      {icons.map((icon, index) => (
        <Box key={index} sx={{ textAlign: "center" }}>
          <IconButton
            onClick={() =>
              icon.subMenu
                ? handleMainMenuClick(icon.subMenu)
                : setSelectedComponent(icon.component)
            }
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={icon.icon} />
          </IconButton>
          <Typography variant="body2" sx={{fontWeight:'bold'}}>{icon.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Appointments':
        return <ServiceProviderAppointments />;
      case 'Responses':
        return <ServiceProviderTherapyAssessments />;
      case 'Billing':
        return <ServiceProviderBills />;
      case 'Sessions':
        return <ServiceProviderTherapySessions />;
      case 'Treatments':
        return <ServiceProviderTherapyTreatments />;
      case 'PostRecovery':
        return <ServiceProviderPostRecovery />;
      
      case 'Questionnaire':
        return <ServiceProviderQuestionnaire />;
      case 'Calendar':
        return <ServiceProviderCalendar />;
      
      case 'Clients':
        return <ServiceProviderClients />;
      case 'Teams':
        return <ServiceProviderStaff />;
      case 'Settings':
        return <ServiceProviderSettings />;
      default:
        return mainMenu ? renderIcons(mainMenuIcons) : null;
    }
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2, pt:2 }}>
      {!mainMenu && ( // Conditionally render back button
          <IconButton onClick={handleBackToMainMenu} sx={{ color: "teal" }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
        )}
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: "#2C1C18" }}>
  <Link
    underline="hover"
    color="inherit"
    onClick={() => {
      setSelectedComponent(null);
      setSubMenu(null);
      setMainMenu(true);
      setBreadcrumbTitle("Home");
    }}
    style={{ cursor: "pointer" }}
  >
    Home
  </Link>
  {subMenu && (
    <Link
      underline="hover"
      color="inherit"
      onClick={() => {
        setSelectedComponent(null); // Clear selected component
        setMainMenu(false); // Stay in sub-menu
        setBreadcrumbTitle(subMenu.charAt(0).toUpperCase() + subMenu.slice(1));
      }}
      style={{ cursor: "pointer" }}
    >
      {breadcrumbTitle}
    </Link>
  )}
  {selectedComponent && (
    <Typography color="text.primary">{selectedComponent}</Typography>
  )}
</Breadcrumbs>

      <Box sx={{ ml: 2, display: isMobile ? 'none' : 'flex', alignItems: 'center', gap: 2, }}>{subMenu && dynamicSubMenu(subMenu)}</Box>
      </Box>
      {/* Render Main Menu or Sub Menu */}
      {mainMenu && !selectedComponent && renderIcons(mainMenuIcons)}

      {/* Sub Menu Rendering */}
      {!mainMenu && !selectedComponent && subMenu === "therapy" && (
        <>
          {renderIcons(therapySubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}
      {!mainMenu && !selectedComponent && subMenu === "financials" && (
        <>
          {renderIcons(financialSubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}
      {!mainMenu && !selectedComponent && subMenu === "administration" && (
        <>
          {renderIcons(adminSubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}

      {/* Render Selected Component */}
      {selectedComponent && (
        <Box sx={{ mt: 4, mb: 4 }}>
          {renderComponent()}
          <Box sx={{ textAlign: "center", my: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </Box>
      )}

      {/* Centered Logout Button */}
      {mainMenu && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <IconButton
            onClick={handleLogout}
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
          </IconButton>
          <Typography color="#F9A64F" variant="body2" sx={{fontWeight:"bold"}}>Logout</Typography>
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ServiceProviderInbox;
